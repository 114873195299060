import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { SetReferralCodeDocument } from "../../../common/gql/graphql";

import { DataService } from "../data/data.service";
import { NotificationService } from "../notification/notification.service";

@Injectable({
    providedIn: 'root',
})
export class ReferralLinkService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private dataService: DataService,
        private notificationService: NotificationService,
    ) {}

    getReferralLink(referralCode?: string | null): string {
        const prefix = isPlatformBrowser(this.platformId) ? window.location.origin : '';
        return prefix + `?referralCode=${referralCode}`;
    }

    validateReferralCode(referralCode: string): void {
        if (isPlatformBrowser(this.platformId)) {
            this.dataService
                .mutate(SetReferralCodeDocument, {
                    code: referralCode,
                })
                .subscribe(({ setReferralCode }) => {
                    if (setReferralCode.success) {
                        this.notificationService
                            .success(`Hi! You've been referred by ${setReferralCode.referredByName}`)
                            .subscribe();
                    } else {
                        this.notificationService
                            .error(`Referral code error: ${setReferralCode.errorMessage}`)
                            .subscribe();
                    }
                });
        }
    }
}
